import * as React from 'react';
import Breadcrumbs from '../components/Breadcrumbs';
import Definition from '../components/DefinitionListItem';
import Layout from '../components/Layout';
import PropTypes from 'prop-types';
import {Text} from '@chakra-ui/react';
import {graphql} from 'gatsby';
const isBrowser = typeof window !== 'undefined';

export default function Definitions({data}) {
  const {articles} = data.allMdx;
  const urlStr = isBrowser ? window.location.href : '';
  const {pathname} = isBrowser ? new URL(urlStr) : '';

  return (
    <Layout>
      {urlStr && <Breadcrumbs slug={pathname} />}
      <Text>
        Le golf étant un sport technique, le jargon qui l&apos;accompagne est
        assez riche, ainsi que quasi exclusivement en anglais.
      </Text>
      {articles.map(blog => (
        <Definition
          key={blog.slug}
          link={`../${blog.slug}`}
          categorie={blog.frontmatter.categorie}
          title={blog.frontmatter.title}
          date={blog.frontmatter.date}
          excerpt={blog.excerpt}
        />
      ))}
    </Layout>
  );
}

export const query = graphql`
  query DefList {
    allMdx(
      filter: {
        frontmatter: {published: {ne: false}, categorie: {eq: "definition"}}
      }
      sort: {fields: slug, order: ASC}
    ) {
      articles: nodes {
        excerpt
        frontmatter {
          title
          date(formatString: "DD MMMM YYYY", locale: "fr-FR")
          categorie
        }
        slug
        timeToRead
      }
    }
  }
`;

Definitions.propTypes = {
  data: PropTypes.node.isRequired
};
